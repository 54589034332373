    :root {
        --background-image-size: calc(var(--unit) * 1.5);
        --primary-color: #ffa500;
        --secondary-color: #ffd000;
        --font-main: 1rem;
        --font-h1: 2rem;
        --font-h2: 1.25rem;
        --font-h3: 1.75rem;
        --font-h4: 1rem;
        --font-footer: 0.75rem;
        --unit: 20px;
    }

#fixed-background {
    position: fixed;
    background-image: url('images/tile-diagonal.png');
    background-size: var(--background-image-size);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #222;
    color: #ccc;
    font-family: Arial, sans-serif;
    font-size: var(--font-main);
    text-align: center;
    margin: 0 auto;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s ease;
}
a:hover {
    color: var(--secondary-color);
}
h1 {
    margin: 0 0 var(--unit) 0;
    font-size: var(--font-h1);
    color: #fff;
    text-wrap: nowrap;

}
h2 {
    margin: calc(var(--unit) * 2) 0 calc(var(--unit) * 0.25) 0;
    font-size: var(--font-h2);
    color: #fff;
}
#mailchimp-section h2 {
    margin: calc(var(--unit) * 2) 0 calc(var(--unit) * 0.5) 0;
    font-size: var(--font-h2);
    color: #fff;
}
h3 {
    margin: 0;
    font-size: var(--font-h3);
}
h4 {
    margin: calc(var(--unit) * 0.25) 0 0 0;
    font-size: var(--font-h4);
    color: #aaa;
}

input, select, textarea {
    width: 100%;
    padding-inline: calc(var(--unit) * 0.5);
    box-sizing: border-box;
    border-radius: calc(var(--unit) * 0.25); /* rounded edges for input fields */
    background-color: #444;
    color: #fff;
    border: none;
    font-size: var(--font-main);
    margin-bottom: calc(var(--unit) * 0.5);
}
input::placeholder, select::placeholder, textarea::placeholder {
    color: #bbb;
}
input, select {
    height: calc(var(--font-main) * 2);
}
button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    padding: 0 calc(var(--unit) * 2) 0 calc(var(--unit) * 2)  !important;
    margin-top: calc(var(--unit) * 0.5);
    cursor: pointer;
    border-radius: calc(var(--unit) * 0.5);
    font-size: var(--font-main);
    font-weight: bold;
    height: calc(var(--font-main) * 2.5);
    transition: background-color 0.3s ease;
}
button:hover {
    background-color: var(--secondary-color);
}
.footer {
    font-size: var(--font-footer);
    color: gray;
}
.page-footer {
  margin: calc(var(--unit) * 2) 0 var(--unit)  0;
}


#main-links {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Additional CSS for justified text */
.justified-text {
    text-align: justify;
}
/* Hide the reCAPTCHA badge */
.grecaptcha-badge { 
    visibility: hidden; 
}
/* Mailchimp form overrides */
#mc-embedded-subscribe-form {
    margin: 0 !important;
    padding: var(--unit) !important;
    background: #333;
    margin-top: var(--unit);
    border-radius: calc(var(--unit) * 0.75); /* rounded edges for form */
}
#mc_embed_signup {
    background: #333 !important;
    padding: 0 !important;
    border-radius: calc(var(--unit) * 0.75) !important;
    border-bottom: calc(var(--unit) * 0.25) solid #111 !important;
}
.mc-field-group {
    width: 100% !important;
    padding: 0 0 calc(var(--unit) * 0.5) !important;
}
#mc_embed_signup input {
    background-color: #444 !important;
    color: #fff !important;
    border: none !important;
    border-radius: calc(var(--unit) * 0.25) !important; 
    margin: 0 !important;
}
#mc_embed_signup input::placeholder {
    color: #bbb !important;
}
#mc_embed_signup .button {
    background-color: var(--primary-color) !important;
    color: black !important;
    border: none !important;
    cursor: pointer !important;
    border-radius: calc(var(--unit) * 0.5) !important;
    margin-top: calc(var(--unit) * 0.5) !important;
    font-size: var(--font-main) !important;
    font-weight: bold !important;
    padding: 0 calc(var(--unit) * 2)  !important;
    height: calc(var(--font-main) * 2.5) !important;
    line-height: calc(var(--font-main) * 2) !important;
    transition: background-color 0.3s ease !important;
}
#mc_embed_signup .button:hover {
    background-color: var(--secondary-color) !important;
}
#mc_embed_signup .mc-field-group {
    min-height: 0 !important;
}
#mc_embed_signup .clear {
    margin-bottom: 0 !important;
    justify-content: center;
    width: 100%;
    display: flex;
}
#mc_embed_signup #mc-embeddedd-subscribe-form div.mce_inline_error {
    font-size: var(--font-main) !important;
    background-color: rgba(255, 255, 255, 0) !important;
}
.home-header {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 100%;
    border-bottom: 1px solid white;
    width: 100%;
}
.page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1200px;
  min-height: 100dvh;
  box-sizing: border-box;
      padding: 0 var(--unit);
    padding-top: var(--unit);
}
#hanchbass {
    z-index: -1;
    height: 100%;
}

#hanchheader {
    color: white;
}

#hanchlogo {
  filter: drop-shadow(calc(var(--unit) * 0.125) calc(var(--unit) * 0.125) 0px #555);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: calc(var(--unit) * 0.75);
  max-width: 800px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  border-bottom: calc(var(--unit) * 0.25) solid #111;
  margin: var(--unit);
}

/* Close Button */
.modal-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  max-width: var(--unit);
  max-height: var(--unit);
  right: calc(var(--unit) * 1.5);
  top: calc(var(--unit) * 1.5);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

#gradient-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 1;
  z-index: -1;
}

@media (max-width: 639px) {
  :root {
      --font-h1: 5vw;
  }
}

@media (max-width: 429px) {
  :root {
      --background-image-size: 7vw;
      --font-main: 3.75vw;
      --font-h2: 5.5vw;
      --unit: 4.5vw;
      --font-footer: 2.75vw;
  }
}

@media (max-width: 1000px) {
  #hanchbass {
    opacity: 0.25
  }
}
